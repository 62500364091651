import React from "react"
import { Page } from "@shopify/polaris"

import Content from "./content"

class Settings extends React.Component {

    state = {
        apiToken: ''
    }

    render() {
        return (
            <Page 
                breadcrumbs={[{content: 'Back', url: '/app'}]}
                title="Settings"
                // primaryAction={{ 
                //     content: 'Save', 
                //     disabled: !apiToken,
                // }}
            >
                <Content 
                    apiToken={this.state.apiToken} 
                    onTokenChange={(value) => {
                        this.setState({apiToken: value})
                    }} 
                />
            </Page>
        )
    }
}

export default Settings
